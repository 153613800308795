var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col w-full h-auto mb-12"
  }, [_c('div', {
    staticClass: "flex w-full h-auto justify-center items-center"
  }, [_c('span', {
    staticClass: "Helvetica text-2xl font-bold text-green-500 justify-center normal-case"
  }, [_vm._v(_vm._s(_vm.$t("shipping_informations")))])]), _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'Livreur' ? _c('div', {
    staticClass: "flex py-2 w-full h-auto justify-center items-center"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('a', {
    staticClass: "mr-5 tracking-wide text-green-700 font-bold rounded border-2 border-yellow-400 hover:border-green-700 hover:bg-green-700 hover:text-white shadow-md py-2 px-6 inline-flex items-center transition duration-300 cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopupModal('open', 'postponed');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("postponed")))]), _c('a', {
    staticClass: "tracking-wide text-green-700 font-bold rounded border-2 border-yellow-400 hover:border-green-700 hover:bg-green-700 hover:text-white shadow-md py-2 px-6 inline-flex items-center transition duration-300 cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopupModal('open', 'unreachable');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("unreachable")))])])]) : _vm._e(), _c('div', {
    staticClass: "px-6"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "flex space-x-2 w-full shadow-xs mt-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full justify-between items-center h-auto p-6"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(0), _c('div', {
    staticClass: "ml-3 w-full p-2"
  }, [_c('div', {
    staticClass: "w-full flex text-base font-semibold text-gray-600"
  }, [_c('p', {
    staticClass: "w-1/2"
  }, [_vm._v(_vm._s(_vm.$t("shipping_informations")))])]), _c('div', {
    staticClass: "bg-white rounded-lg p-6 pb-0"
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("address_shipping"))), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.address,
      expression: "item.address"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "customerAddress",
      "type": "text"
    },
    domProps: {
      "value": _vm.item.address
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item, "address", $event.target.value);
      }
    }
  })])]), _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'StockManager' ? _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "shippdate"
    }
  }, [_vm._v(_vm._s(_vm.$t("shipping_date")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.date,
      expression: "item.date"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "shippdate",
      "type": "date"
    },
    domProps: {
      "value": _vm.item.date
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item, "date", $event.target.value);
      }
    }
  })])]) : _vm._e(), _vm.item.country && _vm.item.country != 'MA' ? _c('div', {
    staticClass: "border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "category"
    }
  }, [_vm._v(_vm._s(_vm.$t("zone_shipping")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    class: {
      disabled: _vm.currentUser.type == 'Courier'
    },
    attrs: {
      "label": "name",
      "options": _vm.zones
    },
    model: {
      value: _vm.item.zone,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "zone", $$v);
      },
      expression: "item.zone"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "country"
    }
  }, [_vm._v(_vm._s(_vm.$t("country")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    class: {
      disabled: true
    },
    attrs: {
      "options": _vm.dataCountry,
      "label": "name"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.code)
          }
        }), _vm._v(" " + _vm._s(option.name) + " ")];
      }
    }]),
    model: {
      value: _vm.item.country,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "country", $$v);
      },
      expression: "item.country"
    }
  })], 1)]), _c('div', {
    staticClass: "border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "courier"
    }
  }, [_vm._v(_vm._s(_vm.$t("courier")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    class: {
      disabled: _vm.currentUser.type == 'Courier'
    },
    attrs: {
      "label": "fullName",
      "options": _vm.couriers
    },
    on: {
      "search": function search($event) {
        return _vm.searchCouriers($event, 'hh');
      }
    },
    model: {
      value: _vm.item.courier,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "courier", $$v);
      },
      expression: "item.courier"
    }
  })], 1)]), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.order.customer.city,
      expression: "item.order.customer.city"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "city",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.item.order.customer.city
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item.order.customer, "city", $event.target.value);
      }
    }
  })])]), _vm.item.order.customer.city && _vm.feesShipp && _vm.item.country == 'MA' ? _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "Frais",
      "disabled": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("shipping_cost")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('p', [_c('input', {
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "Frais",
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.feesShipp
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.status,
      expression: "item.status"
    }],
    staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
    style: _vm.colorStatus(_vm.item.status),
    attrs: {
      "disabled": _vm.item.status === 'processed' || _vm.item.status === 'return'
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.item, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.onChangeStatus($event);
      }]
    }
  }, [_c('option', {
    staticClass: "bg-white",
    attrs: {
      "selected": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("status")) + " ")]), _vm._l(_vm.datatatus, function (value, index) {
    return _c('option', {
      key: index,
      staticClass: "bg-white text-gray-600",
      domProps: {
        "value": value
      }
    }, [_vm._v(" " + _vm._s(value) + " ")]);
  })], 2)])]), _vm.item.type != 'normal' ? _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "type"
    }
  }, [_vm._v(_vm._s(_vm.$t("type")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.type,
      expression: "item.type"
    }],
    staticClass: "py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.item, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    staticClass: "bg-white",
    attrs: {
      "selected": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("type")) + " ")]), _vm._l(_vm.typesShipp, function (value, index) {
    return _c('option', {
      key: index,
      staticClass: "bg-white text-gray-600",
      domProps: {
        "value": value
      }
    }, [_vm._v(" " + _vm._s(value) + " ")]);
  })], 2)])]) : _vm._e(), _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("comment_order"))), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.order.comment,
      expression: "item.order.comment"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "customerAddress",
      "type": "text"
    },
    domProps: {
      "value": _vm.item.order.comment
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item.order, "comment", $event.target.value);
      }
    }
  })])]), _vm.item.status == 'cancelled' || _vm.item.status == 'refused' ? _c('div', {
    staticClass: "border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "customerAddress"
    }
  }, [_vm._v(_vm._s(_vm.$t("cancelatation_comment_shipping")))])])]), _c('p', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.item.comment,
      expression: "item.comment"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "customerAddress",
      "type": "text"
    },
    domProps: {
      "value": _vm.item.comment
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.item, "comment", $event.target.value);
      }
    }
  })])]) : _vm._e()])])])])])]), _c('div', {
    staticClass: "flex mt-4 shadow-xs rounded-lg"
  }, [_c('div', {
    staticClass: "flex justify-between pb-0 w-full h-auto p-4 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(1), _c('div', {
    staticClass: "ml-1 w-full p-2"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "text-base font-semibold text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$t("products_order")) + " ")]), _vm.item.status != 'processed' ? _c('button', {
    staticClass: "btn ml-auto btn-primary mr-2 mb-2 bg-blue-700 text-white py-2 px-4 items-center focus:outline-none",
    on: {
      "click": function click($event) {
        return _vm.ChangeQuantityOrder(_vm.item);
      }
    }
  }, [_c('svg', {
    staticClass: "lucide block mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M17 2l4 4-4 4"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 11v-1a4 4 0 014-4h14"
    }
  }), _c('path', {
    attrs: {
      "d": "M7 22l-4-4 4-4"
    }
  }), _c('path', {
    attrs: {
      "d": "M21 13v1a4 4 0 01-4 4H3"
    }
  })]), _vm._v(" Change quantity Or TotalPrice ")]) : _vm._e()]), _c('div', {
    staticClass: "w-full mt-4"
  }, [_c('table', {
    staticClass: "min-w-full leading-normal"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("image")) + " ")]), _c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("product")) + " ")]), _c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity")) + " ")]), _c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.$t("price")) + " ")])])]), _vm._l(_vm.item.order.details, function (it, index) {
    return _c('tbody', {
      key: it._id
    }, [_c('tr', {
      class: index % 2 == 0 ? 'bg-gray-100' : ''
    }, [_c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-sm"
    }, [_c('span', {
      staticClass: "flex text-gray-700 capitalize whitespace-no-wrap"
    }, [_c('span', {
      staticClass: "mt-1"
    }, [_c('img', {
      staticClass: "h-30 rounded-md mx-auto",
      attrs: {
        "src": it.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])])]), _c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-sm"
    }, [_c('span', {
      staticClass: "flex text-gray-700 capitalize whitespace-no-wrap"
    }, [_c('span', {
      staticClass: "mt-1"
    }, [_vm._v(_vm._s(it.product.name))])])]), _c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-xs"
    }, [_c('p', {
      staticClass: "text-gray-700 ml-6 whitespace-no-wrap"
    }, [_c('span', {
      staticClass: "font-semibold"
    }, [_vm._v(_vm._s(it.quantity) + " ")])])]), _c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-xs"
    }, [_c('span', {
      staticClass: "justify-center text-xs text-center pl-2 pr-2 py-1 rounded-full text-gray-700"
    }, [_c('span', {
      staticClass: "text-gray-800 font-semibold text-xs ml-1"
    }, [_vm._v(" " + _vm._s(it.unitPrice) + " ")]), _vm.idWharhouse ? _c('sup', {
      staticClass: "uppercase"
    }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])])]);
  }), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "px-6 text-right py-2 whitespace-no-wrap border-gray-200",
    attrs: {
      "colspan": "5"
    }
  }, [_c('div', {
    staticClass: "text-xs leading-5 capitalize px-2 rounded-full font-bold text-gray-900"
  }, [_vm._v(" " + _vm._s(_vm.$t("total_price")) + ": " + _vm._s(_vm.item.order.totalPrice) + " "), _vm.idWharhouse ? _c('sup', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])])])], 2)])])])])]), _c('div', {
    staticClass: "tab-cc w-full s-sidebarwidget s-sidebarwidget__yellow s-anchors s-anchors__grayscale"
  }, [_c('div', {
    staticClass: "d-block p-0 m-0"
  }, [_c('div', {
    staticClass: "s-sidebarwidget--header flex s-sidebarwidget__small-bold-text fc-light d:fc-black-900 bb bbw1"
  }, [_c('svg', {
    staticClass: "fill-current mr-2",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
    }
  })]), _vm._v(" Change in order of cases ")]), _c('div', {
    staticClass: "s-sidebarwidget--item"
  }, [_c('table', {
    staticClass: "w-full"
  }, [_c('tbody', [_c('tr', [_vm._m(2), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(3)]), _c('tr', [_vm._m(4), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(5)])])]), _c('hr', {
    staticClass: "hr-grey"
  }), _c('table', {
    staticClass: "w-full"
  }, [_c('tbody', [_c('tr', [_vm._m(6), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(7)]), _c('tr', [_vm._m(8), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(9)]), _c('tr', [_vm._m(10), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(11)]), _c('tr', [_vm._m(12), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(13)])])]), _c('hr', {
    staticClass: "hr-grey"
  }), _c('table', {
    staticClass: "w-full"
  }, [_c('tbody', [_c('tr', [_vm._m(14), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(15)]), _c('tr', [_vm._m(16), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(17)]), _c('tr', [_vm._m(18), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _vm._m(19)])])])])])])])]), _c('span', {
    staticClass: "flex w-full px-6",
    attrs: {
      "id": 'return-' + _vm.item._id
    }
  }, [_vm.item.status == 'return' ? _c('div', {
    staticClass: "flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full mt-1"
  }, [_vm._m(20), _c('div', {
    staticClass: "ml-5 w-full pb-4"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("products_returned")) + " ")]), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('table', {
    staticClass: "min-w-full w-full"
  }, [_c('thead', {
    staticClass: "text-white"
  }, [_c('tr', {
    staticClass: "bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
  }, [_c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("image")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_sent")) + " ")]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_returned")) + " ")])])]), _c('tbody', {
    staticClass: "flex-1 sm:flex-none"
  }, _vm._l(_vm.item.order.details, function (it, index) {
    return _c('tr', {
      key: index,
      staticClass: "flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
    }, [_c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2"
    }, [_c('div', [_c('img', {
      staticClass: "h-16 w-16 rounded-md mx-auto",
      attrs: {
        "alt": it.product.name,
        "src": it.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.product.name))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('span', [_vm._v(_vm._s(it.quantity))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('table', {
      staticClass: "min-w-full"
    }, [_c('thead', [_c('tr', [_c('th', {
      staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("ok_good")) + " ")]), _vm.CheckQteReturnedexist ? _c('th', {
      staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("defective")) + " ")]) : _vm._e()])]), _c('tbody', {
      staticClass: "bg-white"
    }, [_c('tr', [_c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.product.qteChange,
        expression: "it.product.qteChange"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "Defective",
        "disabled": _vm.CheckQteReturnedexist,
        "type": "number",
        "max": it.quantity,
        "min": "0"
      },
      domProps: {
        "value": it.product.qteChange
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.product, "qteChange", $event.target.value);
        }
      }
    })])]), _vm.CheckQteReturnedexist ? _c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_vm._v(" " + _vm._s(it.quantity - it.product.qteChange) + " ")])]) : _vm._e()])])])])]);
  }), 0)])])])])])]) : _vm._e()]), _vm.item.type == 'change' ? _c('div', {
    staticClass: "flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg"
  }, [_c('div', {
    staticClass: "flex w-full mt-1"
  }, [_vm._m(21), _c('div', {
    staticClass: "ml-5 w-full pb-4"
  }, [_c('div', {
    staticClass: "text-base mt-1 font-semibold text-gray-600 pb-10"
  }, [_vm._v(" " + _vm._s(_vm.$t("products_change")) + " ")]), _c('div', {
    staticClass: "w-full space-y-4"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('table', {
    staticClass: "min-w-full w-full"
  }, [_c('thead', {
    staticClass: "text-white"
  }, [_c('tr', {
    staticClass: "bg-green-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
  }, [_c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("image")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(_vm._s(_vm.$t("quantity")))]), _c('th', {
    staticClass: "p-2 justify-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_returned")) + " ")])])]), _c('tbody', {
    staticClass: "flex-1 sm:flex-none"
  }, _vm._l(_vm.item.order.details, function (it, index) {
    return _c('tr', {
      key: index,
      staticClass: "flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
    }, [_c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2"
    }, [_c('div', [_c('img', {
      staticClass: "h-16 w-16 rounded-md mx-auto",
      attrs: {
        "alt": it.product.name,
        "src": it.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.product.name))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('b', [_vm._v(_vm._s(it.quantity))])]), _c('td', {
      staticClass: "border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate"
    }, [_c('table', {
      staticClass: "min-w-full"
    }, [_c('thead', [_c('tr', [_c('th', {
      staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("ok_good")) + " ")]), _vm.CheckQteDefecteuseexist ? _c('th', {
      staticClass: "text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
    }, [_vm._v(" " + _vm._s(_vm.$t("defective")) + " ")]) : _vm._e()])]), _c('tbody', {
      staticClass: "bg-white"
    }, [_c('tr', [_c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.product.qteChange,
        expression: "it.product.qteChange"
      }],
      staticClass: "py-2 border-4 border-light-blue-500 border-opacity-100 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "id": "Defective",
        "disabled": _vm.CheckQteDefecteuseexist,
        "type": "number",
        "max": it.quantity,
        "min": "0"
      },
      domProps: {
        "value": it.product.qteChange
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it.product, "qteChange", $event.target.value);
        }
      }
    })])]), _vm.CheckQteDefecteuseexist ? _c('td', {
      staticClass: "px-6 py-4 border whitespace-no-wrap"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_vm._v(" " + _vm._s(it.quantity - it.product.qteChange) + " ")])]) : _vm._e()])])])])]);
  }), 0)])])])])])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-500"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("To prepare")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("OutOfStock")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("OutOfStock")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("To prepare")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("To prepare")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Prepared")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Prepared")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Shipped")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Shipped")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Delivered")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Delivered")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Paid")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Shipped Or Delivered")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Cancelled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Shipped Or Delivered")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Refused")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('b', [_vm._v("Cancelled Or Refused Or Paid")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Return")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-200"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);

}]

export { render, staticRenderFns }